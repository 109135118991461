var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-scale-transition',[_c('BaseForm',{attrs:{"backRoute":" ","apiActions":"unit/unit.php","newRecordTitle":"Nova organizacijska jedinica","listRefreshAction":"unit_list_needs_refresh","formData":[
      {
        title: 'OSNOVNI PODACI',
        rows: [
          {
            id: 1,
            fields: [
              {
                label: 'Naziv',
                type: 'text',
                name: 'name'
              },
              {
                label: 'Opis',
                type: 'text',
                name: 'description'
              }
            ]
          },
          {
            id: 2,
            fields: [
              {
                label: 'Adresa',
                type: 'text',
                name: 'address'
              },
              {
                label: 'Grad',
                type: 'text',
                name: 'city'
              },
              {
                label: 'Poštanski broj',
                type: 'text',
                name: 'postal_code'
              },
              {
                label: 'Država',
                type: 'country',
                name: 'country'
              }
            ]
          }
        ]
      },
      {
        title: 'POSLOVNI PODACI',
        rows: [
          {
            id: 3,
            fields: [
              {
                label: 'E-mail',
                type: 'email',
                name: 'email'
              },
              {
                label: 'E-mail 1',
                type: 'email',
                name: 'email1'
              }
            ]
          },
          {
            id: 4,
            fields: [
              {
                label: 'Telefon 1',
                type: 'phone',
                name: 'phone'
              },
              {
                label: 'Telefon 2',
                type: 'phone',
                name: 'phone1'
              },
              {
                label: 'Fax',
                type: 'phone',
                name: 'fax'
              },
              {
                label: 'Mobitel',
                type: 'phone',
                name: 'mobile'
              }
            ]
          }
        ]
      },
      {
        title: 'OSTALI PODACI',
        rows: [
          {
            id: 9,
            fields: [
              {
                label: 'Napomena',
                type: 'text',
                name: 'note'
              }
            ]
          },
          {
            id: 10,
            fields: [
              {
                label: 'Ažurirano',
                type: 'text',
                name: 'last_update',
                disabled: true
              },
              {
                label: 'Kreirano',
                type: 'text',
                name: 'created',
                disabled: true
              },
              {
                label: 'Brisano',
                type: 'text',
                name: 'deleted',
                disabled: true
              }
            ]
          }
        ]
      }
    ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }